import React from "react";
import "./Contact.css"
import { Link } from "react-router-dom";

function Contact(){
    return(
            <div class="ccontainer">
                <div class="information">
                    <div class="innerinformation">
                        <div class="informationtext">
                            Email: agentile@purdue.edu
                        </div>
                        <div class="informationtext">
                            Phone #: 562-280-9413
                        </div>
                    </div>
                    <div class="button">
                        <Link to="https://www.linkedin.com/in/anthonyvgentile/" target="_blank">Linkedin</Link>
                    </div>
                    <div class="button">
                        <a href={require("./Anthony Gentile Resume-1.pdf")} download="Anthony_Gentile_Resume.pdf">Download Resume</a>
                    </div>
                </div>
            </div>
    );
}

export default Contact

