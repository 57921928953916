import React from "react";
import "./Projects.css";
import useEmblaCarousel from 'embla-carousel-react'

function generateSlide(propImages){
    const images = []
    propImages.forEach((image) => {
        images.push(<div className="embla__slide">    
        <img class="pimage" src={image} alt="Project"/>
        </div>)
    })
    return images
}


function ProjectSection(props){
    const [emblaRef] = useEmblaCarousel()
    const images = generateSlide(props.images)
    return(
        <div class={props.isReversed === "true" ? 'psectioncontainerreverse' : 'psectioncontainer'}>
            <div class="pimagecontainer" ref={emblaRef}>
                <div className="embla__container">        
                    {images}
                         
                </div>
                <div class="caption">
                    (swipe to change image)
                </div>  
            </div>
            <div class="ptextcontainer">
                <div class={props.isReversed === "true" ? 'ptitlereverse' : 'ptitle'}>
                     {props.title}
                </div>
                <div class={props.isReversed === "true"? 'pdescriptionreverse' : 'pdescription'}>
                {props.description}
                </div>
            </div>
        </div>
    );
}

export default ProjectSection;

