import React from "react";
import ProjectSection from "./ProjectSection";

const courseraterImages = [require("../../images/Courserater_1.PNG"), require("../../images/Courserater_2.PNG"), require("../../images/Courserater_3.PNG"), require("../../images/Courserater_4.PNG"), require("../../images/Courserater_5.PNG"), require("../../images/Courserater_6.PNG")]
const dkoImages = [require("../../images/DKO_1.png"), require("../../images/DKO_2.png"), require("../../images/DKO_3.png"), require("../../images/DKO_4.PNG"), require("../../images/DKO_5.PNG")]
const climeImages = [require("../../images/CLIME_1.png"), require("../../images/CLIME_2.PNG"), require("../../images/CLIME_3.PNG"), require("../../images/CLIME_4.PNG")]
const ece570Images = [require("../../images/570_1.png"), require("../../images/ECE570_2.png"), require("../../images/ECE570_3.png"), require("../../images/ECE570_4.PNG"), require("../../images/ECE570_5.PNG"), require("../../images/ECE570_6.PNG")]
const ece547Images = [require("../../images/547_1.PNG"), require("../../images/547_2.png")]




function Projects(){
    return(
        <div>
            <div class="psectionscontainer">
                <ProjectSection images={courseraterImages} isReversed="true"
                title="CourseRater"
                description="Starting a while ago, my friend and I had seen many posts on Reddit and heard many discussions related to the difficulty of schedules and realized that the current resources available were a little lacking. 
                There is a lot of information about classes that can’t be known just from GPA data or credits associated with a course. 
                So, we decided to create a website that allows you to view GPA data for Purdue classes as well as a Rate My Professors style of review for courses. 
                For instance, you can report whether there are midterms, how much homework there is, if there is a final, etc. giving a fuller picture on how hard and time consuming a course might be. 
                Anyone can view GPA data for the course as well as the reviews. I worked on the majority of the backend of this including the user, review, course, and reporting functionality that is on the site using NodeJS and MongoDB."
                />
            </div>
            <div class="psectionscontainer">
                <ProjectSection images={dkoImages} isReversed="true"
                title="Deaf Kids Outreach"
                description='In my freshman year, 2021-2022 I was a part of a team called Deaf Kids Outreach in the EPICS organization. 
                We sought to create a website that could be used by instructors who could manage Khan Academy videos that could be ASL interpreted.
                I was responsible for the managing page of videos that was called the "My Videos" page where you could see videos you have claimed,
                view feedback on your interpreted videos, and resubmit/ unclaim videos. I used ReactJS and worked purely frontend on this project.'
                />
            </div>
            <div class="psectionscontainer">
            <ProjectSection images={climeImages} isReversed="true"
                title="Communication in Loud Industrial Manufacturing Environments"
                description="Starting in Fall 2023, I have been the Design Lead for a brand new project that we call CLIME.
                The goal of this project is to increase situational awareness and allow better communication for workers in loud environments.
                In addition to leading the project, I was mainly responsible for an audio classifier built with PyTorch and Librosa.
                I created a simple Convolutional Neural Network and my own dataset based off of six different classes that might be heard in a factory.
                I trained and tested the classifier with this dataset and confirmed that it was accurate with high accuracy scores. 
                There is an image that illustrates a rough outline of an eventual hardware creation of the device we could make, but for now we plan to use mobile devices
                as a proof of concept. Furthermore, there is another image which shows how the audio classifier can be tested on audios it hasn't seen before with the last audio being a sound with a class the model has never heard before, so it's low confidence."
            />
            </div>
            <div class="psectionscontainer">
            <ProjectSection images={ece570Images} isReversed="true"
                title="White Box Adversarial Defense Final Project"
                description="For our final project in ECE 57000: Introduction to Artifical Intelligence, we were supposed to reimplement a paper from a popular AI conference.
                I chose to reimplement a paper looking at ways to face new adversarial attacks that were able to get around previous defenses like gradient obfuscation.
                I was able to implement two white box adversarial attacks (Least Likely Projected Gradient Descent and Fast Gradient Sign Method) and show imperfect, yet promising results of a way to
                defend against them. The implementation was to do adversarial training of the model to build its robustness. However, the way that the paper I was implementing changes things, is that you choose 
                to use high perturbation adversarial images instead of low perturbation, so that the model can be better at recognizing adversarial features."
                />
                </div>
            <div class="psectionscontainer">
            <ProjectSection images={ece547Images} isReversed="true"
                title="Survey of Load Balancing in Fog Computing"
                description="In ECE 54700: Introduction to Computer Communication Networks, we had a semester long project where we could conduct a survey on current network technology.
                I knew that fog computing was gaining a lot of traction, and I'm very interested in load balancing because I've worked with it in past projects, so I decided to combine these and take a survey.
                I looked at a variety of different load balancing methods and compiled and compared them. These load balancing methods were all very distinct from one another.
                Additionally, I had noticed that previous surveys in this field would often provide too little information on how these methods actually worked, so I made sure to cover
                these papers more in depth with a smaller sample of papers."
                />
            </div>
        </div>
    );
}

export default Projects

