import React from "react";
import './Home.css'

function Home(){
    return(
        <div class="homecontainer">
            <div class="aboutcontainer">
                <div class="abouttext">
                    <div class="introduction">
                       Anthony Gentile
                    </div>
                    <div class="description">
                        I'm a student studying Computer Engineering at Purdue University in West Lafayette, Indiana.
                        I am very passionate about programming and problem solving, and am seeking an internship in software engineering.
                        
                    </div>
                </div>
            </div>
            <div class="imagecontainer">
                <img class="portrait" src={require("./images/portrait - Copy.png")} alt="Portrait"></img>
            </div>
            <div>
            </div>
        </div>
    );
}

export default Home

