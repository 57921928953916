import React from "react";
import "./Sample.css";
import "../projects/Projects.css"


import useEmblaCarousel from 'embla-carousel-react'

function generateSlide(propImages){
    const images = []
    propImages.forEach((image) => {
        images.push(<div className="embla__slide">    
        <img class="pimage" src={image} alt="Project"/>
        </div>)
    })
    return images
}


function SampleSection(props){
    const [emblaRef] = useEmblaCarousel()
    const images = generateSlide(props.images)
    return(
        <div class={props.isReversed === "true" ? 'ssectioncontainerreverse' : 'ssectioncontainer'}>
            <div class="pimagecontainer">
                <div className="embla__container">        
                    {images[0]}
                         
                </div>
                <div class="caption">
                    {props.captions[0]}
                </div>  
            </div>
            <div class="pimagecontainer">
                <div className="embla__container">        
                    {images[1]}
                         
                </div>
                <div class="caption">
                    {props.captions[1]}
                </div>  
            </div>
        </div>
    );
}

export default SampleSection;

