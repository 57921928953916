import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../paths/home/Home"
import Contact from "../paths/contact/Contact";
import Projects from "../paths/projects/Projects";
import Navbar from "./Navbar";
import Sample from "../paths/sample/Sample"
import "./Router.css";

function Router(){
    return(
        <BrowserRouter>
            <div class="flex">
                <Navbar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/projects" element={<Projects/>}/>
                    <Route path="/samplecode" element={<Sample/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default Router

