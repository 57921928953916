import React, { useState } from "react";
import './Navbar.css'
import { Link } from "react-router-dom";

// @janv code for this
class ClickableLink extends React.Component {
    render () {
      const { onCustomClick, ...props } = this.props
      return <a {...props} onClick={this.handleClick} />
    }
    handleClick = event => {
      if (this.props.onClick) {
        this.props.onClick(event)
      }
  
      if (this.props.onCustomClick) {
        this.props.onCustomClick(event)
      }
    }
  
}
  


function Navbar(){
    const [dropdown, setDropDown] = useState(0)
    function clickHamburger(e){
        if(dropdown === 0)
            setDropDown(1)
        else   
            setDropDown(0)
    }
    if(dropdown === 0){
        return(
            <div class="navbar">
                <button class="hamburger" onClick={clickHamburger}>
                    <div class="hamburgerbar"></div>
                    <div class="hamburgerbar"></div>
                    <div class="hamburgerbar"></div>
                </button>                
                <div class="container">
                    <h1>AVG</h1>
                    <ul>
                    <li>
                        <Link class="navbarlink"to="/">Home
                            </Link>
                    </li>
                    <li>
                        <Link class="navbarlink" to="/projects">Projects</Link>
                    </li>
                    <li>
                        <Link class="navbarlink" to="/samplecode">Sample Code</Link>
                    </li>
                    <li>
                        <Link class="navbarlink" to="/contact">Contact</Link>
                    </li>
                    </ul>
    
                </div>
            </div>
    
        );
    }else{
        return(
            <div class="navbar">
                <button class="hamburger" onClick={clickHamburger}>
                    <div class="hamburgerbar"></div>
                    <div class="hamburgerbar"></div>
                    <div class="hamburgerbar"></div>
                </button>
                <div class="container">
                    <h1>AVG</h1>
                    <ul>
                    <li>
                        <Link class="navbarlink" to="/">Home 
                        </Link>
                    </li>
                    <li>
                        <Link class="navbarlink" onClick={() => clickHamburger()} to="/projects">Projects</Link>
                    </li>
                    <li>
                        <Link class="navbarlink" to="/samplecode">Sample Code</Link>
                    </li>
                    <li>
                        <Link class="navbarlink" to="/contact">Contact</Link>
                    </li>
                    </ul>
    
                </div>
                <div class='dropdown'>
                    <ul>
                    <li>
                        <Link class="dropdownlink"  to="/">
                            <ClickableLink onCustomClick={() => clickHamburger()}>
                                Home
                            </ClickableLink>
                            </Link>
                    </li>
                    <li>
                        <Link class="dropdownlink" to="/projects">
                            <ClickableLink onCustomClick={() => clickHamburger()}>
                                Projects
                            </ClickableLink>
                        </Link>
                    </li>
                    <li>
                        <Link class="dropdownlink" to="/samplecode">                            
                            <ClickableLink onCustomClick={() => clickHamburger()}>
                                Sample Code
                            </ClickableLink>
                        </Link>
                    </li>
                    <li>
                        <Link class="dropdownlink" to="/contact">
                            <ClickableLink onCustomClick={() => clickHamburger()}>
                                Contact
                            </ClickableLink>
                        </Link>
                    </li>
                    </ul>
                </div>
            </div>
    
        );
    }
}

export default Navbar

