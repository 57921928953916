import React from "react";
import "./Sample.css"
import SampleSection from "./SampleSection";

const images = [require("../../images/sample_1.PNG"), require("../../images/sample_2.PNG"), require("../../images/sample_3.PNG"),
                require("../../images/sample_4.PNG"), require("../../images/sample_5.PNG"), require("../../images/sample_6.PNG"),
                require("../../images/sample_7.PNG"), require("../../images/sample_8.PNG")]
const captions = ["giveBallAndRemove() is a part of a sports based Minecraft Server that I have been working on since early 2022. It's main purpose" +
                  " is to make sure that when a player receives a ball that ball no longer physically exists. For instance, if a basketball is on the ground, " + 
                  " once it is picked up it should no longer be attainable by other players.",
                  "getConnectionType() is a function responsible for illustrating how the player is currently associated with the server. So for instance, "+
                  "if the player is in the proxy but not in the immediate server, it would return IN_PROXY. This is very handy for cross-server commands.",
                  "DataManager is a manager class dedicated to having different utility functions for accessing Redis/ MongoDB. subscribe() subscribes to a " +
                  "given channel and puts the output on the listener that you choose. I use Pub/Sub for cross-server communication so this is helpful.",
                  "Seen is a very simple Dockerfile for a given Minecraft proxy. It simply copies over the template and begins the server.",
                  "This is a sample docker compose for one of the setups of a server. This specific one sets up a Redis server, a lobby, a game, and the proxy on specified ports."
                  + " In production, this would be placed on a server and edited as necessary with Redis only being ona centralized Redis server.",
                  "In early 2022, I wanted to learn what it takes to make a very simple game engine in Java. In this code I am making a class that would " +
                  "be used for different things that needed a size, position, as well as id (so basically most things in a game).",
                  "A component is a part of a gameobject. So essentially it is something that will change how the GameObject works. In this code, " + 
                  "I make it so that the component has a parent which is the GameObject it is bound to, as well as a type.",
                  "The RigidBody is a component meant for movement. Thus there are different velocities vectors associated with it. The velocities seen " + 
                  "within this component affect the parent GameObject. Eventually, I was planning on adding gravity, but I never got to it."]

function Sample(){
    return(
        <div>
            <div class="samplecontainer">
                <div class="sampletext">
                    While some of my projects are listed in the Projects section, these are mainly the major ones I've worked on.
                    To give some idea of how I code, this will illustrate samples of code I've taken from side/ passion projects I have worked on.
                    Below each image will have a caption that gives a general idea of what the code is doing in the given side project.
                    These images will kind of be all over the place, but I'd just like to show a brief overview of some of the code I've done.
                </div>
                <div class="sampleimages">
                    <SampleSection images={images.slice(0,2)} captions={captions.slice(0,2)}></SampleSection>
                    <SampleSection images={images.slice(2, 4)} captions={captions.slice(2,4)}></SampleSection>
                    <SampleSection images={images.slice(4, 6)} captions={captions.slice(4,6)}></SampleSection>
                    <SampleSection images={images.slice(6, 8)} captions={captions.slice(6,8)}></SampleSection>
                </div>
                
            </div>
        </div>
    );
}

export default Sample

