import logo from './logo.svg';
import './App.css';
import Router from './router/Router';
import Navbar from './router/Navbar';

function App() {
  return (
    <div>
      <Router>

      </Router>
    </div>
  );
}

export default App;
